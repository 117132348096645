import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, margins, mobileThresholdPixels } from '../styledComponents';
import { Title1, Subtitle2, Button, Label, Input as InputBase } from '../Home/v2/StyledComponents';
import Dots from '../Home/Dots';
import Input from '../Input';
import Radio from '../InputRadio';
import logo from '../../assets/logos/icon1024-navy.png';

const Background = styled.div`
  background-color: ${colors.navy};
  min-height: 100vh;
  width: 100vw;
  z-index: 4;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  margin: ${margins.x2l} 0px ${margins.xl};
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.xl} ${margins.s};
  }
`;

const Container = styled.div`
  background-color: ${colors.white};
  padding: ${margins.x2l} ${margins.xl} ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: ${margins.xl} ${margins.l} ${margins.l};
  }
`;

const Logo = styled.img`
  max-width: ${margins.x2l};
  margin-top: -${margins.xl};
  margin-bottom: -${margins.xl};
  z-index: 5;

  @media (max-width: ${mobileThresholdPixels}) {
    max-width: ${margins.xl};
    margin-top: -${margins.l};
    margin-bottom: -${margins.l};
  }
`;

const Title = styled.h3`
  color: ${colors.navy};
  font-family: Roboto;
  font-weight: 500;
  font-size: 32px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const hasClickedInsideModale = (evt) => {
  const flyoutElement = document.getElementById('modal');
  let targetElement = evt.target; // clicked element

  do {
    if (targetElement === flyoutElement) {
      return true;
    }
    // Go up the DOM
    targetElement = targetElement.parentNode;
  } while (targetElement);

  return false;
};

const PluginModal = ({ onChange, alterationsType, alterationsOffer, alterationsOfferAmount, closeModal, submit }) => (
  <Background onClick={e => !hasClickedInsideModale(e) && closeModal()}>
    <Modal id="modal">
      <Logo src={logo} alt="" onClick={closeModal} />
      <Container>
        <Title1>Recevoir mon plugin Tilli</Title1>
        <Subtitle2>
          Merci de renseigner les informations ci-dessous<br />
          pour créer votre plugin
        </Subtitle2>

        <Title>Personnalisation du plugin</Title>
        <Input
          label="Nom de votre marque*"
          id="brand"
          onChange={e => onChange('brandName', e.target.value)}
        />
        <Input
          label="Couleur du bouton à intégrer*"
          id="color"
          placeholder="#......"
          onChange={e => onChange('brandColor', e.target.value)}
        />

        <div>
          <Label>Nature des retouches à intégrer</Label>
          <Radio
            value="clothes"
            onClick={() => onChange('alterationsType', 'clothes')}
            checked={alterationsType === 'clothes'}
          >Vêtements</Radio>
          <Radio
            value="curtains"
            onClick={() => onChange('alterationsType', 'curtains')}
            checked={alterationsType === 'curtains'}
          >Décoration</Radio>
          <Radio
            value="all"
            onClick={() => onChange('alterationsType', 'all')}
            checked={alterationsType === 'all'}
          >Les deux</Radio>
        </div>

        <div>
          <Label>Offre retouche pour mes clients*</Label>
          <Radio
            value="100%"
            onClick={() => onChange('alterationsOffer', '100%')}
            checked={alterationsOffer === '100%'}
          >Je souhaite offrir 100%</Radio>
          <Radio
            value="amount"
            onClick={() => onChange('alterationsOffer', 'amount')}
            checked={alterationsOffer === 'amount'}
          >
            Je souhaite offrir
            <InputBase
              inline
              width="80px"
              value={alterationsOfferAmount}
              onChange={e => onChange('alterationsOfferAmount', e.target.value)}
              type="text"
              id={'alterationsOfferAmount'}
              name={'alterationsOfferAmount'}
              placeholder={'montant (€)'}
            />
            sur les retouches
          </Radio>
          <Radio
            value="no"
            onClick={() => onChange('alterationsOffer', 'no')}
            checked={alterationsOffer === 'no'}
          >Je souhaite uniquement proposer le service</Radio>
        </div>

        <Row><Dots /></Row>

        <Title>Fiche contact</Title>
        <Input
          label="Nom et prénom de la personne référente*"
          id="contactName"
          onChange={e => onChange('contactName', e.target.value)}
        />
        <Input
          label="Adresse mail*"
          id="contactEmail"
          onChange={e => onChange('contactEmail', e.target.value)}
        />
        <Input
          label="Numéro de téléphone*"
          id="contactPhone"
          onChange={e => onChange('contactPhone', e.target.value)}
        />

        <Row>
          <Button onClick={submit}>
            Valider ma demande
          </Button>
        </Row>
      </Container>
    </Modal>
  </Background>
);

PluginModal.propTypes = {
  onChange: PropTypes.func,
  closeModal: PropTypes.func,
  submit: PropTypes.func,
  alterationsType: PropTypes.string,
  alterationsOffer: PropTypes.string,
  alterationsOfferAmount: PropTypes.string,
};

PluginModal.defaultProps = {
  onChange() {},
  closeModal() {},
  submit() {},
  alterationsType: '',
  alterationsOffer: '',
  alterationsOfferAmount: '',
};

export default PluginModal;
