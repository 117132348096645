import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label, Input as InputBase } from './Home/v2/StyledComponents';
import { margins } from './styledComponents';

const Container = styled.div`
  margin: ${margins.m} 0px;
`;

const Input = ({ label, id, placeholder, onChange }) => (
  <Container>
    <Label htmlFor={id}>{label}</Label>
    <br />
    <InputBase
      type="text" id={id} name={id} required
      placeholder={placeholder}
      onChange={onChange}
    />
  </Container>
);

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  label: '',
  placeholder: '',
  onChange() {},
};

export default Input;
